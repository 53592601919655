import { useState } from "react";
import { useTranslate } from "react-admin";
import { useQuery } from "react-query";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { listFace } from "../../../api/merchant";
import { getAllPendingOperationsCount } from "../../../api/signer";
import { IsignerGetOperation } from "../../../api/types";
import { Box, Container } from "../../../components/MuiGenerals";
import { usePermission } from "../../../hooks";
import useTabs from "../../../hooks/useTabs";
import { INTERVAL_PENDING_OPERATIONS_COUNT } from "../../../utils/config";
import {
  approvalAutoString,
  enumNewCreatorType,
  enumNewMerchantOperationStatus,
  enumOperationType,
  FeatureCodes,
} from "../../../utils/constant";
import { translateErrorCode } from "../../../utils/errorHandler";
import {
  displayAmount,
  enumMapping,
  findChainInfo,
  getDecimal,
  jsonParse,
  listMappingTransform,
  toDisplayTime,
} from "../../../utils/helper";
import { DivideLine } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { useZusTabsStore } from "../../../zustand/store";
import { ApprovalDialog, UploadFileDialog } from "./";
import ManualVerificationDialog from "./ManualVerificationDialog";
import FilterBar from "./MerchantOperationFilterBar";
import MerchantOperationList, {
  MoreDetailsDialog,
  ReEstimateDialog,
} from "./MerchantOperationList";

const Pkey = FeatureCodes.assetManagement.MerchantOperation;
const translatePrefix = "assetManagement";
const Enum = {
  newMerchantOperationStatus: enumMapping(enumNewMerchantOperationStatus),
  creatorType: enumMapping(enumNewCreatorType),
  operationType: enumMapping(enumOperationType),
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index} //hidden all if no index==value
      id={`simple-tabpanel-${index}`} //showing which tab
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && ( //if value match, then display the target tab-content
        // <Box sx={{ p: 3 }}>{children}</Box>
        <Box>{children}</Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function MerchantOperation() {
  const [tabsIndex, setTabsIndex] = useState(0); //the default index :which tab
  const translate = useTranslate();
  const { hasPermission } = usePermission();
  const zusTabsStore = useZusTabsStore((state) => state);

  const t = (key: string) => translate(`assetManagement.${key}`);
  const handleChange = (event: React.SyntheticEvent, tabsEnum: number) => {
    zusTabsStore.setGridTabsEumn(0);
    setTabsIndex(tabsEnum);
  };
  const resCount = useAllPendingOperationsCount();

  //{- - merchantOperation_Tabs - -}
  //{- -all: the first summary Page- -- }
  const operationTabs = [
    {
      name: "all",
      path: Pkey.TabAll.prefix,
      jsxNode: <TabView operationTab={"all"} />,
      countDom: false,
    },
    {
      name: "api_withdraw_tab",
      path: Pkey.TabApiWithdraw.prefix,
      jsxNode: <TabView operationTab={"api_withdraw_tab"} />,
      // countDom: `(${String(resCount.api_withdraw)})`,
      countDom: <PendingCount count={Number(resCount.api_withdraw)} />,
    },
    {
      name: "portal_withdraw_tab",
      path: Pkey.TabPortalWithdraw.prefix,
      jsxNode: <TabView operationTab={"portal_withdraw_tab"} />,
      // countDom: `(${String(resCount.portal_withdraw)})`,
      countDom: <PendingCount count={Number(resCount.portal_withdraw)} />,
    },
    {
      name: "sweep_tab",
      path: Pkey.TabSweep.prefix,
      jsxNode: <TabView operationTab={"sweep_tab"} />,
      // countDom: `(${String(resCount.sweep)})`,
      countDom: <PendingCount count={Number(resCount.sweep)} />,
    },
  ].filter((item) => hasPermission(item.path));

  //
  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
      }}
    >
      <Tabs
        sx={{ margin: "0 1.5rem", borderBottom: "solid #E1E4EA 1px" }}
        value={tabsIndex}
        onChange={handleChange}
      >
        {operationTabs.map((item, index) => {
          const name = (
            <Box sx={{ display: "flex", gap: "0.2rem", alignItems: "center" }}>
              {t(item.name)}
              {item.countDom}
            </Box>
          );

          return <Tab key={index} label={name} {...a11yProps(index)} />;
        })}
      </Tabs>
      {/* {- - Real Tab Content- - -} */}
      {operationTabs.map((item, index) => {
        return (
          <TabPanel key={index} value={tabsIndex} index={index}>
            {item.jsxNode}
          </TabPanel>
        );
      })}
    </Box>
  );
}
export type IoperationTab =
  | "all"
  | "api_withdraw_tab"
  | "portal_withdraw_tab"
  | "sweep_tab";
function TabView(props: { operationTab: IoperationTab }) {
  const { operationTab } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const [page, setPage] = useState(0);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [refreshBatchTable, setRefreshBatchTable] = useState(false);
  const [uploadDialog, setUploadDialog] = useState({ bol: false, info: {} });
  const tabsArr = [{ name: t("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return (
    //{[ - - customeLayoutBox for Switch Tabs -- ]}
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          setList={setList}
          count={list.count}
          setPage={setPage}
          page={page}
          refreshBatchTable={refreshBatchTable}
          setRefreshBatchTable={setRefreshBatchTable}
          listMapping={listMapping}
          operationTab={operationTab}
        />
      </Container>
      <DivideLine />
      <>
        <Container
          style={customSx.datagridContainer}
          maxWidth={false}
          disableGutters
        >
          <Tabs>
            <MerchantOperationList
              {...{
                list,
                page,
                setPage,
                setUploadDialog,
                listMapping,
                tabName: operationTab,
              }}
            />
          </Tabs>
        </Container>
        <MoreDetailsDialog operationTab={operationTab} />
        <ApprovalDialog
          setRefreshBatchTable={setRefreshBatchTable}
          operationTab={operationTab}
        ></ApprovalDialog>
        <ManualVerificationDialog
          setRefreshBatchTable={setRefreshBatchTable}
        />
        <ReEstimateDialog tabName={operationTab} />
        <UploadFileDialog
          uploadDialog={uploadDialog}
          setUploadDialog={setUploadDialog}
          setRefreshBatchTable={setRefreshBatchTable}
          operationTab={operationTab}
        />
      </>
    </Box>
  );
}
function translteRemark(str: string) {
  const [notJSON, json] = jsonParse(str);
  if (notJSON) return str;
  const { code = "", data = {} } = json;
  return translateErrorCode(code, data);
}
const listMapping = (
  which: "key" | "name",
  array: IsignerGetOperation[],
  config?: any
): any[][] => {
  const { omitKeys = [], translate } = config || {};
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const te = (key: string) => translate(`enumConstants.${key}`);
  const res = array.map((item) => {
    const decimal = getDecimal(item);
    const toAmount = (amount: number | string) =>
      displayAmount(amount, decimal);
    const approval_completed = item?.approval_completed ?? NaN;
    const approval_required = item?.approval_required ?? NaN;
    const approval_ratio = `${Math.min(
      approval_completed,
      approval_required
    )}/${approval_required}`;
    const isAuto =
      item?.transaction_approval_details.length === 1 &&
      item?.transaction_approval_details?.[0]?.operated_by?.toLowerCase() ===
        approvalAutoString;
    const approval_progress = isAuto ? te(approvalAutoString) : approval_ratio;
    const chain_code = findChainInfo(
      String(item?.chain_type),
      item?.chain_id
    )?.chain_code;
    const mappedResult = [
      ["batch_id", t("batch_id"), item.batch_id],
      ["approval_progress", t("approval_progress"), approval_progress],
      [
        "creator_type",
        t("creator_type"),
        te(Enum.creatorType[item.creator_type]),
      ],
      [
        "operation_type",
        t("operation_type"),
        te(Enum.operationType[item.operation_type]),
      ],
      ["status", t("status"), te(Enum.newMerchantOperationStatus[item.status])],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(String(item?.chain_type), item?.chain_id)?.name,
      ],
      ["asset_name", t("asset_name"), item.asset_name],
      ["total_amount", t("totalAmount"), toAmount(item.total_amount)],
      ["total_operation", t("total_operation"), item.total_operation],
      ["created_by", t("created_by"), item.created_by],
      ["created_date", t("created_date"), toDisplayTime(item.created_date)],
      ["last_executed_by", t("last_executed_by"), item.last_executed_by],
      [
        "last_executed_date",
        t("last_executed_date"),
        toDisplayTime(item.last_executed_date),
      ],
      [
        "transaction_approval_details",
        "transaction_approval_details",
        item.transaction_approval_details,
      ],
      [
        "last_modified_date",
        t("last_modified_date"),
        toDisplayTime(item.last_modified_date),
      ],
      [
        "miner_fee_asset_name",
        t("miner_fee_asset_name"),
        item.miner_fee_asset_name,
      ],
      [
        "estimated_miner_fee",
        t("estimated_miner_fee"),
        displayAmount(
          item.estimated_miner_fee,
          item.estimated_miner_fee_decimals
        ),
      ],
      [
        "prepared_miner_fee",
        t("prepared_miner_fee"),
        displayAmount(
          item.prepared_miner_fee,
          item.prepared_miner_fee_decimals
        ),
      ],
      ["remarks", t("remark"), translteRemark(item.remarks)],
      [
        "last_estimated_miner_fee_date",
        t("last_estimated_miner_fee_date"),
        toDisplayTime(item.last_estimated_miner_fee_date),
      ],
      ["chain_id", "", item.chain_id],
      ["chain_type", "", item.chain_type],
      [
        "rawData",
        "",
        item,
      ],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
export function useAllPendingOperationsCount(enabled: boolean = true) {
  const refetchInterval = Number(INTERVAL_PENDING_OPERATIONS_COUNT) * 1000;
  const res = useQuery(
    "getAllPendingOperationsCount",
    getAllPendingOperationsCount,
    { refetchInterval, enabled }
  );
  return {
    all: String(res.data?.all || ""),
    api_withdraw: String(res.data?.api_withdraw || ""),
    portal_withdraw: String(res.data?.portal_withdraw || ""),
    sweep: String(res.data?.sweep || ""),
  };
}
export function PendingCount(p: { count: number }) {
  if (!p.count || p.count <= 0) return null;
  const newCount = p.count > 99 ? "99+" : p.count;
  return (
    <Box
      sx={{
        backgroundColor: "secondary.main",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "clamp(1.8rem,1.8rem, 1.8rem)",
        height: "clamp(1.8rem,1.8rem, 1.8rem)",
        borderRadius: ".4rem",
        fontSize: "0.8rem",
        cursor: "pointer",
      }}
    >
      {newCount}
    </Box>
  );
}
