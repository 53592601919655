import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { OperationStatus } from "@wallet-manager/node-types/dist/src/postgres/const";
import { useTranslate } from "react-admin";

import {
  InputAdornment,
  Container,
  Box,
  SelectChangeEvent,
} from "../../components/MuiGenerals";
import { NodeTypesEnumMultiSelection } from "../../components/GeneralSelection";
import {
  tableConfig,
  enumOperationType,
  FeatureCodes,
} from "../../utils/constant";
import { getWalletOperations, listFace } from "../../api/merchant";
import { OperationsSearchParamsFace } from "../../api/types";
import { customSx } from "../../utils/styling";
import OperationRecordList from "./OperationRecordsList";
import { DivideLine, genField } from "../../utils/HelperComp";
import { useTabs, usePermission, useAlerting } from "../../hooks";
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  getDecimal,
  getFullApiResponse,
  toDisplayTime,
  separateBatchStrings,
  listMappingTransform,
  findEnumKey,
} from "../../utils/helper";
import { useAssets, useChains } from "../../components/FetchConfig";
import { useDatePicker } from "../../components/DatePicker";
import { ChainSingleSelection } from "../../components/ChainSelection";
import { AssetMultiSelection } from "../../components/AssetSelection";
import GeneralBtn from "../../components/GeneralBtn";
import MpTextField from "../../components/MpTextField";
import { useZusDialogStore } from "../../zustand/store";

const Pkey = FeatureCodes.assetManagement.OperationRecords;
const rawSearchParams = {
  batchId: "",
  chain_name: "",
  assetName: [],
  statuses: [],
  merchantOrderIdsString: "",
  merchant_order_ids: [],
};

export default function OperationRecords() {
  const translate = useTranslate();
  const t = (key: string) => translate(`operations.${key}`);
  const [page, setPage] = useState(0);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const tabsArr = [{ name: t("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          setList={setList}
          setPage={setPage}
          page={page}
          count={list.count}
          listMapping={listMapping}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <OperationRecordList
            list={list}
            setPage={setPage}
            page={page}
            listMapping={listMapping}
          />
        </Tabs>
      </Container>
    </Box>
  );
}

function FilterBar(props: any) {
  const translate = useTranslate();
  const { hasPermission } = usePermission();
  const t = (key: string) => translate(`operations.${key}`);
  const tc = (key: string) => translate(`common.${key}`);
  const { alerting } = useAlerting();
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const { setList, setPage, page, count, listMapping } = props;
  const [searchedParams] = useSearchParams();
  const initSearchParams = {
    ...rawSearchParams,
    batchId: searchedParams.get("batchId") || "",
  };
  const [fields, setFields] =
    useState<OperationsSearchParamsFace>(initSearchParams);
  const DateObj = {
    Created: useDatePicker(),
    Modified: useDatePicker(),
  };
  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  const setAssets = (assetName: string[]) =>
    setFields((fields) => ({ ...fields, assetName }));
  const getParams = () => {
    const { chain_name, batchId, merchantOrderIdsString, ...rest } = fields;
    const { chain_id, chain_type } = selectChainByPieces({ chain_name });
    const merchant_order_ids = separateBatchStrings(merchantOrderIdsString);
    const batch_ids = separateBatchStrings(batchId);

    return {
      ...rest,
      created_date_from: DateObj.Created.start,
      created_date_to: DateObj.Created.end,
      last_modified_date_from: DateObj.Modified.start,
      last_modified_date_to: DateObj.Modified.end,
      batch_ids,
      merchant_order_ids,
      chain_id,
      chain_type,
      page,
      pageSize: tableConfig.pageSize,
    };
  };

  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  useEffect(() => {
    const fetch = async () => {
      zusDialog.openExtra("loadingDialog");
      const res = await getWalletOperations({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    };
    fetch();
  }, [page, cacheParams]);

  useEffect(() => {
    setAssets([]);
  }, [fields.chain_name]);

  const onSearch = async () => {
    setPage(0);
    setCacheParams(params);
  };
  const onReset = () => {
    setFields(rawSearchParams);
    DateObj.Created.clearDate();
    DateObj.Modified.clearDate();
  };

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getWalletOperations({ ...params, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count);
    if (rawRes.length === 0) return;
    const res = listMapping("name", rawRes, translate);
    const config = {};
    downloadFiles(`Operation Transaction Records`, res, config);
  };

  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const F = genField({ t }, [
    ["created_date", <DateObj.Created.Picker type="dateTime" />],
    ["last_modified_time", <DateObj.Modified.Picker type="dateTime" />],
    [
      "batch_id",
      <MpTextField
        fullWidth={true}
        name="batchId"
        label={t("phBatch_id")}
        value={fields.batchId}
        onChange={onChange("batchId")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.batch_ids.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "merchant_order_id",
      <MpTextField
        label={t("phMerchantOrderIds")}
        value={fields.merchantOrderIdsString}
        onChange={onChange("merchantOrderIdsString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.merchant_order_ids.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.assetName}
        allItems={allAssetNames}
      />,
    ],
    [
      "status",
      <NodeTypesEnumMultiSelection
        label={t("phStatus")}
        value={fields.statuses}
        onChange={(statuses) => setFields((f) => ({ ...f, statuses }))}
        enumData={OperationStatus}
      />,
    ],
  ]);

  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        <GeneralBtn
          label="export"
          isHidden={!hasPermission(Pkey.Export)}
          onClick={onExport}
        />
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.created_date}
        {F.last_modified_time}
        {F.chain_name}
        {F.asset_name}
        {F.status}
        {F.batch_id}
        {F.merchant_order_id}
      </Box>

      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  translate: any
): any[][] => {
  const t = (key: string) => translate(`operations.${key}`);
  const res = array.map((item: any) => {
    const { asset_name } = item;
    const { chain_type, chain_id } = item.batch;
    const decimal = getDecimal({ asset_name, chain_type, chain_id });
    const toAmount = (amount: number) => displayAmount(amount, decimal);
    const mappedResult = [
      ["batch_id", t("batch_id"), item.batch_id],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(String(item?.batch?.chain_type), item?.batch?.chain_id)
          ?.name,
      ],
      ["operation_seq", t("operation_seq"), item.operation_seq],
      ["merchant_order_id", t("merchant_order_id"), item.merchant_order_id],
      ["wallet_version", t("wallet_version"), item.wallet_version],
      [
        "operation_type",
        t("operation_type"),
        translate(
          `enumConstants.${findEnumKey(enumOperationType, item.operation_type)}`
        ),
      ],
      ["from_address", t("from_address"), item.from_address],
      ["to_address", t("to_address"), item.to_address],
      ["to_wallet_tag", t("to_wallet_tag"), item.to_wallet_tag],
      ["invoker_address", t("invoker_address"), item.invoker_address],
      [
        "status",
        t("status"),
        translate(`enumConstants.${OperationStatus[item.status]}`),
      ],
      ["asset_name", t("asset_name"), asset_name],
      ["amount", t("amount"), toAmount(item.amount)],
      ["created_by", t("created_by"), item?.batch?.created_by],
      [
        "created_date",
        t("created_date"),
        toDisplayTime(item?.batch?.created_date),
      ],
      [
        "last_modified_date",
        t("last_modified_date"),
        toDisplayTime(item?.batch?.last_modified_date),
      ],
      ["rawData", "", item],
    ];
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
