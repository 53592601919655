//ApprovalWindow

import BigNumber from 'bignumber.js';
import flow from 'lodash/flow';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { getWalletManagement } from '../../../api/merchant';
import { CustomPagination } from '../../../components/CustomPagination';
import { Box } from '../../../components/MuiGenerals';
import { NoRowsOverlay } from '../../../components/NoRowsOverlay';
import { WalletTypeIconWithAddress } from '../../../components/WalletTypeIconWithAddress';
import {
  enumNewApprovalStatus,
  enumOperationType
} from '../../../utils/constant';
import {
  enumMapping,
  formatLargeNumberforBigNumber,
  toDisplayTime
} from '../../../utils/helper';
import { useGenGridCol } from '../../../utils/HelperComp';
import { useZusDialogStore } from '../../../zustand/store';
import OpDatagridFooter from './OpDatagridFooter';
import { BatchStatus } from '@wallet-manager/node-types/dist/src/signer';
  interface VerificationRow {
    manual_verified_by: string;
    manual_verified_time: string;
    manual_verified_result: string;
  }
  
  interface VerifyWindowProps {
    row: any;
  }



  
  const VerifyWindow: React.FC<VerifyWindowProps> = ({ row }) => {
    // Type guard to ensure rows conform to VerificationRow structure
    const translate = useTranslate();
    const t = (key: string, configAmt = {}) =>
        translate(`assetManagement.${key}`, configAmt);

    const verifiedText = t('verified')
    const verifiedFailedText = t('verifiedFailed')

    const verifiedArray = [BatchStatus.SignFailed, BatchStatus.Rejected, BatchStatus.Approved, BatchStatus.Prepared, BatchStatus.Signed, BatchStatus.Verified]
    
    const getStatusMapping = (status: BatchStatus) => {
      return verifiedArray.includes(status) ? verifiedText : verifiedFailedText
    }

    if (!row.rawData.manual_verified_date) return <></>

    return (
      <Box
        sx={{ width: "830px" }}
      >
        <div style={{ margin: '0 0 15px 0' }}>{t('verification')}</div>
        
          <Table aria-label="verification table" sx={{ mb: 3, borderCollapse: 'separate', borderSpacing: '0px 4px' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: 'none', backgroundColor: '#f5f5f5', padding: '10px', textAlign: 'left' }}>{t('verified_by')}</TableCell>
                <TableCell sx={{ border: 'none', backgroundColor: '#f5f5f5', padding: '10px', textAlign: 'left' }}>{t('verified_time')}</TableCell>
                <TableCell sx={{ border: 'none', backgroundColor: '#f5f5f5', padding: '10px', textAlign: 'left' }}>{t('verified_result')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
          <TableRow
            key={row.id}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              background: '#EAEAEA',
            }}
          >
            <TableCell>{row.rawData.manual_verified_by || 'N/A'}</TableCell>
            <TableCell>{toDisplayTime(row.rawData.manual_verified_date) || 'N/A'}</TableCell>
            <TableCell>{getStatusMapping(row.rawData.status as BatchStatus)}</TableCell>
          </TableRow>
        </TableBody>
          </Table>
    
        <div
          style={{
            borderBottom: 'solid #F1F2F4 1px',
            margin: '10px 0 10px 0',
          }}
        ></div>
      </Box>
    );
  };
  
  export default VerifyWindow;