import { useEffect } from "react";
import {
  Container,
  Box,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "../../../components/MuiGenerals";

import { useTranslate } from "react-admin";
import { useState } from "react";
import { customSx } from "../../../utils/styling";
import { DivideLine, genField } from "../../../utils/HelperComp";
import {
  enumActions,
  enumBroadcastBatchStatus,
  enumOperationType,
  tableConfig,
} from "../../../utils/constant";
import {
  enumMapping,
  listMappingTransform,
  separateBatchStrings,
  sortItemsAlphabetically,
  toDisplayTime,
} from "../../../utils/helper";
import { useZusDialogStore, useZusListStore } from "../../../zustand/store";
import { useDatePicker } from "../../../components/DatePicker";
import HistoryTabList from "./HistoryTabList";
import { getStuckTokenHistory } from "../../../api/merchant";
import { Ifields, translatePrefix } from "./rebroadcastTypes";
import GeneralBtn from "../../../components/GeneralBtn";
import MpTextField from "../../../components/MpTextField";

const { pageSize } = tableConfig;

const Enum = {
  enumOperationType: enumMapping(enumOperationType),
  enumActions: enumMapping(enumActions),
  enumBatchStatus: enumMapping(enumBroadcastBatchStatus),
};

///
const HistoryTab = () => {
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <HistoryTabList listMapping={listMapping} />
      </Container>
    </Box>
  );
};

export const initFields = {
  action: "",
  status: [],
  operation_type: "",
  batchIdsString: "",
};
export function FilterBar() {
  const [fields, setFields] = useState<Ifields>(initFields);
  const DateObj = {
    Created: useDatePicker(),
  };
  const zusList = useZusListStore();
  const page = zusList.meta.page;
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);

  const getParams = () => {
    const { status, operation_type, batchIdsString, action } = fields;

    return {
      created_date_from: DateObj.Created.start,
      created_date_to: DateObj.Created.end,
      status: status,
      operation_type: operation_type ? Number(operation_type) : [],
      handle_type: action ? Number(action) : [],
      batchIds: separateBatchStrings(batchIdsString),
      page: page,
      pageSize: pageSize,
    };
  };

  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  useEffect(() => {
    async function fetch() {
      zusDialog.openExtra("loadingDialog");
      const res = await getStuckTokenHistory({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      zusList.setGeneralList(res);
    }
    fetch();
  }, [page]);
  const handleClick = (e: any, key: any, filterField: string) => {
    if (filterField === "operation_type") {
      if (key === fields.operation_type)
        setFields((fields) => ({
          ...fields,
          operation_type: "",
        }));
    }
    if (filterField === "action") {
      if (key === fields.action)
        setFields((fields) => ({
          ...fields,
          action: "",
        }));
    }
  };
  const onSearch = async () => {
    zusDialog.openExtra("loadingDialog");
    zusList.setPage(0);
    setCacheParams(params);
    const res = await getStuckTokenHistory(params);
    zusDialog.closeExtra();
    if (!res) return;
    zusList.setGeneralList(res);
  };
  const onReset = () => {
    setFields(initFields);

    DateObj.Created.clearDate();
  };
  const onArraySelectChange = (
    e:
      | SelectChangeEvent<string[] | string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let newValue = typeof value === "string" ? value.split(",") : value;
    if (newValue.every((el) => el === "")) newValue = [];
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };
  const onChange =
    (type: keyof Ifields) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const onSelectChange = (type: string) => (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    return setFields((fields: any) => ({
      ...fields,
      [type]: value,
    }));
  };

  const F = genField({ t }, [
    ["operation_time", <DateObj.Created.Picker type="dateTime" />],
    [
      "operation_type",
      <FormControl>
        <InputLabel>{t("phOperation_type")}</InputLabel>
        <Select
          name="operation_type"
          value={fields.operation_type}
          onChange={onSelectChange("operation_type")}
        >
          {Object.entries(enumOperationType).map(([name, value]) => {
            const newName = name as keyof typeof enumOperationType;
            return (
              <MenuItem
                key={value}
                value={value}
                onClick={(e) => handleClick(e, value, "operation_type")}
              >
                {translate(`enumConstants.${newName}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>,
    ],
    [
      "action",
      <FormControl>
        <InputLabel>{t("phAction")}</InputLabel>
        <Select
          name="action"
          value={fields.action}
          onChange={onSelectChange("action")}
        >
          {Object.entries(enumActions).map(([name, value]) => {
            const newName = name as keyof typeof enumOperationType;
            return (
              <MenuItem
                key={value}
                value={value}
                onClick={(e) => handleClick(e, value, "action")}
              >
                {translate(`enumConstants.${newName}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>,
    ],
    [
      "batch_id",
      <MpTextField
        fullWidth={true}
        label={t("phBatch_id")}
        value={fields.batchIdsString}
        onChange={onChange("batchIdsString")}
      />,
    ],

    [
      "BatchStatus",
      <FormControl>
        <InputLabel id="select-label">{t("ph_BatchStatus")}</InputLabel>
        <Select
          multiple
          name="status"
          value={fields.status}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(
            Object.entries(enumBroadcastBatchStatus)
          ).map(([name, value]: any, i: string) => (
            <MenuItem key={i} value={value}>
              {t(`${name}`.toLowerCase())}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.filterCmdBar}></Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.operation_time}
        {F.batch_id}
        {F.operation_type}
        {F.action}
        {F.BatchStatus}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  { translate }: any
): any[][] => {
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const te = (key: string) => translate(`enumConstants.${key}`);

  const res = array.map((item: any) => {
    const mappedResult = [
      ["id", "", item.id],
      ["batch_id", t("batch_id"), item.batch_id],
      [
        "operation_type",
        t("operation_type"),
        t(Enum.enumOperationType[item.batch_operation_type]),
      ],
      ["action", t("action"), te(`${Enum.enumActions[item.handle_type]}`)],
      [
        "BatchStatus",
        t("BatchStatus"),
        te(`${Enum.enumBatchStatus[item.batch_status]}`),
      ],
      ["operated_by", t("operated_by"), item.created_by],
      [
        "operation_time",
        t("operation_time"),
        toDisplayTime(item.batch_last_modified_date),
      ],
    ];
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};

export default HistoryTab;
