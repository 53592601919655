import OpTextButton from "../../../components/OpTextButton";
import { composeSyncValidators, useTranslate } from "react-admin";
import { useZusDialogStore } from "../../../zustand/store";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "../../../components/MuiGenerals";
import {
  approveOperations,
  rejectOperations,
  executeOperations,
  manualVerifyOperations,
  manualVerificationRejectOperations,
} from "../../../api/signer";
import { useAlerting, usePermission, useYubiPrompt } from "../../../hooks";
import { customSx } from "../../../utils/styling";
import {
  enumMapping,
  findChainInfo,
  getDecimal,
  displayAmount,
} from "../../../utils/helper";
import { useAppSelector } from "../../../reducer/hooks";
import { selectProfile } from "../../../reducer/profileSlice";
import {
  FeatureCodes,
  enumApprovalReason,
  enumNewApprovalStatus,
  enumOperationType,
} from "../../../utils/constant";
import ApprovalWindow from "./ApprovalWindow";
import { IoperationTab } from "./MerchantOperation";
import { AxiosResponse } from "axios";
import YubiPrompter from "../../../components/Prompter";
import ManualVerificationWindow from "./ManualVerificationWindow";
  
const Pkey = FeatureCodes.assetManagement.MerchantOperation;

interface ItransactionApprovalDetails {
    seq: number;
    operated_by: string;
    approval_result: number;
    operated_date: string;
  }

export default function ManualVerificationDialog(props: any) {
    const { setRefreshBatchTable } = props;
    const zusDialog = useZusDialogStore();
    const rowData = zusDialog.meta?.row || {};
    const tabName = (zusDialog.meta?.tabName || "all") as IoperationTab;

    const { batch_id = "-1" } = rowData;
    const { getResAfterYubi, prompterConfig } = useYubiPrompt();
    const { hasPermission } = usePermission();
    const translate = useTranslate();
    const t = (key: string, configAmt = {}) =>
        translate(`assetManagement.${key}`, configAmt);

    const { alerting } = useAlerting();
    const { email } = useAppSelector(selectProfile);
    const getRes = (
        apiFn: (apiParams: any) => Promise<AxiosResponse<any, any>>,
        apiParams: any
      ) => {
        const isWithdraw = //if the operationType is withdraw, then requrie "YubiKey"
          String(rowData.rawData.operation_type) === enumOperationType.Withdraw;
        if (!isWithdraw) {
          return apiFn(apiParams);
        }
        return getResAfterYubi(apiFn, apiParams);
      };

    const toExecute = async () => {
        const res = await getRes(executeOperations, { batch_id });
    
        if (!res) {
          return;
        }
        alerting("success", t("transactionExecuted"));
        setRefreshBatchTable(true);
        zusDialog.close();
      };
    const curProgress = rowData.rawData?.approval_completed; //check the progress
    
    const isFull = rowData.rawData?.approval_completed === rowData.rawData?.approval_required;
    const isOperatedBefore = rowData?.transaction_approval_details?.find(
        (item: any) => item.operated_by === email
      );
    const isCreator = rowData?.created_by === email;

    const toOperation = async (operation: "verify" | "deny") => {
        const isWithdraw =
          String(rowData.rawData.operation_type) === enumOperationType.Withdraw;
    
        const sweepApprovalChecking = !hasPermission(Pkey.Approve.prefix);
    
        const withdrawManualVerifyChecking = !hasPermission(Pkey.Approve.prefix);
    
        const approvalChecking =
          operation === "verify" &&
          (isWithdraw ? withdrawManualVerifyChecking : sweepApprovalChecking);
    
        const noPermission =
          approvalChecking ||
          (operation === "deny" && !hasPermission(Pkey.Reject));
    
        if (noPermission) {
          return alerting("error", t("alert_no_approval_permission"));
        }
        if (operation === "verify" && isOperatedBefore) {
          return alerting("error", t("alert_approvers_same"));
        }
        if (operation === "verify" && isCreator) {
          return alerting("error", t("alert_creator_approver_same"));
        }
    
        if (operation === "verify") {
          
          const approveParams = {
            batch_id: batch_id,
            merchant_id: rowData.rawData?.merchant_id,
            email: rowData.created_by,
          };

          const res = await getRes(manualVerifyOperations, approveParams);
          if (!res) return;
          alerting("success", t("transactionApproved"));
        } else if (operation === "deny") {
            const rejectParams = {
                batch_id: batch_id,
                merchant_id: rowData.rawData?.merchant_id,
                email: rowData.created_by,
            };    
        
          const res = await getRes(manualVerificationRejectOperations, rejectParams);
          if (!res) return;
          alerting("success", t("transactionRejected"));
        }
        setRefreshBatchTable(true);
        zusDialog.close();
      };
      const historyRows: ItransactionApprovalDetails[] = rowData?.transaction_approval_details || [];
      const isOperation = zusDialog.meta?.from === "operation";
      
      if (zusDialog.main !== "manualVerificationDialog") return null;
        const showRejectButton =
            hasPermission(Pkey.Reject) &&
            (tabName === "all" ||
            tabName === "api_withdraw_tab" ||
            tabName === "portal_withdraw_tab" ||
            tabName === "sweep_tab");
    return (
        <>
            <YubiPrompter {...prompterConfig} />
            <Dialog
                open={zusDialog.match("manualVerificationDialog")}
                fullWidth={true}
                onClose={zusDialog.close}
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        minWidth: "880px",
                        minHeight: "300px",
                      },
                    },
                  }}
            >
                <DialogTitle
                    style={{
                        padding: "3px 24px",
                    }}
                >
                    {t("manualVerification")}{" "}
                    <div
                        style={{
                        textDecoration: "none",
                        textAlign: "right",
                        transform: "translateY(-23px)",
                        }}
                    >
                        <IconButton
                            onClick={zusDialog.close}
                            sx={{
                                position: "absolute",
                                right: "0px",
                                top: "0px",
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <section>
                        <div
                            style={{
                                fontSize: "10px",
                                marginLeft: "5px",
                                minWidth: "150px",
                                marginBottom: "20px",
                                }}
                        >
                            {t("batch_id")}: 
                            <span style={{ color: "#4a90f7" }}> {rowData.batch_id}</span>
                        </div>
                    </section>
                    <ManualVerificationWindow rows={historyRows} isOperation={isOperation} />
                </DialogContent>
                <DialogActions sx={customSx.dialogBtnMargin}>
                    {isOperation && (
                        <>
                            {isFull && (
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={toExecute}
                                >
                                    {t("execute")}
                                </Button>
                            )}
                            {!isFull && (
                                <OpTextButton
                                    text={t("verify")}
                                    // isHidden={!hasPermission(Pkey.Approve.prefix)}
                                    sx={{ borderRadius: ".3rem" }}
                                    onClick={() => toOperation("verify")}
                                />
                            )}
                            <OpTextButton
                                text={t("deny")}
                                // isHidden={!showRejectButton}
                                sx={{ borderRadius: ".3rem" }}
                                onClick={() => toOperation("deny")}
                            />
                        </>
                    )}
                    <Button variant="contained" color="error" onClick={zusDialog.close}>
                        {t("cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}