import axios from "./axiosInstance";
import { listFace } from "./merchant";
import {
  IlistResult,
  IsignerGetOperation,
  IoperationDetailsRes,
} from "./types";

export async function postAddressList(params: any, config?: any) {
  const input = { ...params, ...config };
  return await axios.post<never, any>(`signer/import_addresses`, input);
}

export async function WithdrawThreshold(
  params: {
    pageSize: number;
    page: number;
    chain_type?: number | string;
    chain_id?: number | string;
    asset_names?: string[];
  },
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<any, listFace>(`signer/withdraw_threshold`, input);
}
export async function getRequestRecordList(
  params: {
    pageSize: number;
    page: number;
    chain_type?: number | string;
    chain_id?: number | string;
    asset_names?: string[];
  },
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<any, listFace>(
    `signer/withdraw_threshold_request`,
    input
  );
}
export async function alterWithdrawRequestApprovalSetting(
  //add request to alter the existing threshold for auto approve
  input: {
    // pageSize: number;
    // page: number;
    chain_type?: number | string;
    chain_id?: number | string;
    asset_name?: string;
    // amount: number | string;
    otp?: string;
  },
  config?: any
) {
  // const { pageSize, page, ...rest } = params;
  // const input = params;
  return await axios.post<any, listFace>(
    `signer/withdraw_threshold_request`,
    input
  );
}
export async function approveWithDrawApprovalSetting(
  //add request to alter the existing threshold for auto approve
  input: {
    id: number | string;
  },
  config?: any
) {
  return await axios.post<any, listFace>(
    `signer/withdraw_threshold_request/approve`,
    input
  );
}
export async function rejectWithDrawApprovalSetting(
  //add request to alter the existing threshold for auto reject
  input: {
    id: number | string;
  },
  config?: any
) {
  return await axios.post<any, listFace>(
    `signer/withdraw_threshold_request/reject`,
    input
  );
}

export async function uploadTransactionDetails(
  batch_id: string,
  text: any,
  config?: any
) {
  const input = {
    batch_id: batch_id,
    data: text,
    ...config,
  };
  return await axios.post(`signer/upload_transaction`, input);
}

interface IoperationParams {
  batch_id: any;
  otp: string;
}

export async function manualVerifyOperations(
  params: IoperationParams & {
    batch_id: number,
    merchant_id: number,
    email: string,
  },
  config?: any
){
  const input = { ...params, ...config };
  return await axios.post(`/signer/manual_verify_operation`, input)
}

export async function manualVerificationRejectOperations(
  params: IoperationParams & {
    merchant_id: number,
    batch_id: number,
    email: string,
  },
  config?: any
){
  const input = { ...params, ...config };
  return await axios.post(`/signer/manual_verify_reject_operation`, input)
}

export async function approveOperations(
  params: IoperationParams & {
    approval_completed: string;
    operation_type: string;
  },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post(`signer/approve_operation`, input);
}

export async function rejectOperations(
  params: IoperationParams | { batch_id: any },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post(`signer/reject_operation`, input);
}
export async function executeOperations(
  params: IoperationParams | { batch_id: any },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post(`signer/execute_operation`, input);
}
export async function getWithdrawCheckingTimeControl(config?: any) {
  const result = await axios.get<null, { withdraw_checking_time: number }>(
    `/signer/withdraw_checking_time_control`,
    config
  );
  return result;
}
export async function getWithdrawCheckingTimeControlRequest(
  params: any,
  config?: any
): Promise<any> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get(
    `/signer/withdraw_checking_time_control_request`,
    input
  );
}
export async function postWithdrawCheckingTimeControlRequest(
  params: { otp: string; withdrawCheckingTime: number },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post(
    `/signer/withdraw_checking_time_control_request/create`,
    input
  );
}

export async function approveWithdrawCheckingTimeControlRequest(
  params: { otp: string; id: string },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post<never, any>(
    `/signer/withdraw_checking_time_control_request/approve`,
    input
  );
}

export async function rejectWithdrawCheckingTimeControlRequest(
  params: { otp: string; id: string },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post<never, any>(
    `/signer/withdraw_checking_time_control_request/reject`,
    input
  );
}
export async function getAllPendingOperationsCount(config?: any) {
  return await axios.get<
    null,
    {
      all: number;
      api_withdraw: number;
      portal_withdraw: number;
      sweep: number;
    }
  >(`/signer/all_pending_operations_count`, config);
}

export async function getLatestNonce(params: any, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post<never, any>(
    `/signer/repairing_tools/get_latest_nonce`,
    input
  );
}

export async function getNoncePriorityTab(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    ...rest,
    limit: pageSize,
    offset: pageSize * page,
    ...config,
  };
  return await axios.post<never, any>(
    `/signer/repairing_tools/get_priority_nonce`,
    input
  );
}
export async function postInsertNonce(
  params: {
    chain_type: number;
    chain_id: number;
    address: string;
    nonce: string;
  },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post<never, any>(
    `/signer/repairing_tools/insert_nonce`,
    input
  );
}
export async function postDeleteNonce(
  params: { id: string; chain_type: number; chain_id: number; address: string },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post<never, any>(
    `/signer/repairing_tools/delete_nonce`,
    input
  );
}
