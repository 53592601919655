import { create } from "zustand";

import { listFace } from "../api/merchant";
import {
  initAssetListContent,
  initMyYubiDetails,
  initOnPortalFields,
  initStateForEdit,
  initStateForWatch,
} from "./initialStatesFactory";
import {
  IAssetList,
  IAuditLog,
  IRefetchStore,
  IRepeatedLoginStore,
  IShowIconStore,
  ITabsStore,
  IWithDrawApprovalSettingStore,
  IYubiKeyStore,
} from "./types";

interface IgeneralList extends listFace {
  meta: {
    page: number;
  };
  setGeneralList: (record: listFace) => void;
  setPage: (page: number) => void;
  clear: () => void;
}

const initZusListStore = {
  meta: { page: 0 },
  count: 0,
  rows: [],
  chain_type: "",
  chain_id: "",
};
export const useZusListStore = create<IgeneralList>((set) => ({
  ...initZusListStore,
  setGeneralList: ({ rows, count, chain_type, chain_id }: listFace) =>
    set(() => ({ rows, count, chain_type, chain_id })),
  setPage: (page) => set(() => ({ meta: { page } })),
  clear: () => set(initZusListStore),
}));

/* usage
  import { useZusDialogStore } from "../../zustand/store";
  const zusDialog = useZusDialogStore();
  zusDialog.open("someDialog");
  zusDialog.close();
  <Dialog
    open={zusDialog.match("someDialog")}
    onClose={...}
    fullWidth={true}
  >
*/
type IzusDialogMain =
  | ""
  | "operationDialog"
  | "recordDialog"
  | "approvalDialog"
  | "manualVerificationDialog"
  | "onProtalOpDialog"
  | "assetListOpDialog"
  | "deleteDialog"
  | "opHistoryDialog"
  | "gen_address_dialog"
  | "address_upload_dialog"
  | "utxoDialog"
  | "utxoDeleteDialog"
  | "create_request_dialog";

type IzusDialogExtra =
  | ""
  | "rejectDialog"
  | "reConfirmDialog"
  | "loadingDialog";
type Imeta = Record<string, any>;
export const useZusDialogStore = create<{
  main: IzusDialogMain;
  extra: IzusDialogExtra;
  meta: Imeta;
  open: (name: IzusDialogMain, meta?: Imeta) => void;
  match: (name: IzusDialogMain) => boolean;
  close: () => void;
  setMeta: (meta?: Imeta) => void;
  openExtra: (name: IzusDialogExtra, meta?: Imeta) => void;
  matchExtra: (name: IzusDialogExtra) => boolean;
  closeExtra: () => void;
  closeAll: () => void;
}>((set, get) => ({
  main: "",
  extra: "",
  meta: {},
  open: (name, meta = {}) => set(() => ({ main: name, meta })),
  match: (name) => get().main === name,
  close: () => set(() => ({ main: "", meta: {} })),
  setMeta: (meta = {}) => set(() => ({ meta })),
  openExtra: (name, meta = {}) => set(() => ({ extra: name, meta })),
  matchExtra: (name) => get().extra === name,
  closeExtra: () => set(() => ({ extra: undefined })),
  closeAll: () => set(() => ({ main: "", extra: undefined })),
}));
interface IZusParams<Body> {
  body: Body;
  setBody: (params: Partial<Body>) => void;
  setPage: (page: number) => void;
  refetch: () => void;
  refetchCounter: number;
  clear: () => void;
}
export const createZusInstance = <T>(initParams: T) =>
  create<IZusParams<T>>((set) => ({
    body: initParams,
    setBody: (params) =>
      set((state) => ({ body: { ...state.body, ...params } })),
    setPage: (page) => set((state) => ({ body: { ...state.body, page } })),
    refetch: () =>
      set((state) => ({ refetchCounter: state.refetchCounter + 1 })),
    refetchCounter: 0,
    clear: () => set({ body: initParams }),
  }));

export const useWithDrawApprovalSettingStore =
  create<IWithDrawApprovalSettingStore>((set) => ({
    configTime: { time: "" },
    recordWatch: initStateForWatch,
    recordForEdit: initStateForEdit,
    totalCountForThresholdRecord: "",
    currentValues: {
      //fix
      // currentThreshold: "",
      curr_hourly_auto_approval_limit: "",
      currentRegulation: [],
      curr_mx_hourly_limit: "",
      curr_mx_addr_hrly_count: "",
      curr_mx_addr_hrly_limit: "",
    },
    setConfigTime: (time) => set(() => ({ configTime: time })),
    setCurrentValues: (payload) =>
      set((state) => ({
        ...state,
        currentValues: {
          ...state.currentValues,
          [payload.editField.field]: payload.editField.value,
        },
      })),
    setTotalCountForThresholdRecord: (total_count) =>
      set(() => ({ totalCountForThresholdRecord: total_count })),
    setRecordWatch: (record) => set(() => ({ recordWatch: record })),
    setRecordForEdit: (record) => set(() => ({ recordForEdit: record })),
    setUpdateRecordForEdit: (payload) =>
      set((state) => ({
        ...state,
        recordForEdit: {
          ...state.recordForEdit,
          rawData: {
            ...state.recordForEdit.rawData,
            [payload.editFields.field]: payload.editFields.value,
          },
        },
      })),
  }));

//{- -For MerchantOperation Only - -}
export const useZusTabsStore = create<ITabsStore>((set) => ({
  gridTabsEumn: 0,
  setGridTabsEumn: (tabIndex) =>
    set((state) => ({ ...state, gridTabsEumn: tabIndex })),
}));

export const useAuditLogStore = create<IAuditLog>((set) => ({
  onPortalDialogDiaLogFields: initOnPortalFields,
  setOnPortalDialogFields: (record) =>
    set(() => ({ onPortalDialogDiaLogFields: record })),
}));
export const useYubiKeysStoreForReg = create<IYubiKeyStore>((set) => ({
  myDetail: initMyYubiDetails,
  setYuBiDetails: (data) => set(() => ({ myDetail: data })),
}));

//AssetList
export const useAssetListStore = create<IAssetList>((set) => ({
  recordWatch: initAssetListContent,
  currentWithdrawStatus: "",
  setRecord: (data) => set(() => ({ recordWatch: data })),
  setCurrentStatus: (data) => set(() => ({ currentWithdrawStatus: data })),
  setRecordWatch: (payload) =>
    set((state) => ({
      ...state,
      recordWatch: {
        ...state.recordWatch,
        [payload.field]: payload.value,
      },
    })),
}));

export const zusRefetchStore = create<IRefetchStore>((set) => ({
  refetchBoolean: false,
  //
  rebroadcastTab: false,
  refetchWalletMgtTab: false,
  yubiKeyRegPage: false,
  withdrawSettingReordTab: false,
  refetchFunc: (payload) =>
    set((state) => ({
      ...state,
      [payload.where]: !state[payload.where as keyof typeof state],
    })),
}));
export const zusShowIconStore = create<IShowIconStore>((set) => ({
  shouldShowIcon: false,
  theSearchHash: "",
  setShowIcon: (payload) => set(() => ({ shouldShowIcon: payload })),
  setSearchHash: (payload) => set(() => ({ theSearchHash: payload })),
}));
