import { useSearchParams } from "react-router-dom";
import {
  Container,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../components/MuiGenerals";
import { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import {
  tableConfig,
  enumOperationType,
  enumOperationTransactionStatus,
  FeatureCodes,
} from "../../utils/constant";
import { getOperationTransaction, listFace } from "../../api/merchant";
import { OperationsTransactionSearchParamsFace } from "../../api/types";
import { customSx } from "../../utils/styling";
import OperationTransactionRecordList from "./OperationTransactionRecordsList";
import { DivideLine, genField, useRefresh } from "../../utils/HelperComp";
import { useTabs, usePermission, useAlerting } from "../../hooks";
import {
  downloadFiles,
  findChainInfo,
  getFullApiResponse,
  sortItemsAlphabetically,
  toDisplayTime,
  listMappingTransform,
  findEnumKey,
} from "../../utils/helper";
import { useAssets, useChains } from "../../components/FetchConfig";
import { useDatePicker } from "../../components/DatePicker";
import { ChainSingleSelection } from "../../components/ChainSelection";
import { AssetMultiSelection } from "../../components/AssetSelection";
import GeneralBtn from "../../components/GeneralBtn";
import MpTextField from "../../components/MpTextField";
import { useZusDialogStore } from "../../zustand/store";
const Pkey = FeatureCodes.assetManagement.OperationTransactionRecords;
export default function OperationTransactionsRecords() {
  const translate = useTranslate();
  const t = (key: string) => translate(`operations.${key}`);
  const [page, setPage] = useState(0);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const tabsArr = [{ name: t("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  const [refreshBool, refresh] = useRefresh();

  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          setList={setList}
          setPage={setPage}
          page={page}
          count={list.count}
          refreshBool={refreshBool}
          listMapping={listMapping}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <OperationTransactionRecordList
            list={list}
            page={page}
            setPage={setPage}
            refresh={refresh}
            listMapping={listMapping}
          />
        </Tabs>
      </Container>
    </Box>
  );
}

const rawSearchParams = {
  batchId: [],
  chain_name: "",
  assetName: [],
  statuses: [],
};

const defaultDates = {
  start: { defaultDate: null },
  end: { defaultDate: null },
};

function FilterBar(props: any) {
  const translate = useTranslate();
  const t = (key: string) => translate(`operations.${key}`);
  const tc = (key: string) => translate(`common.${key}`);
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const { setList, setPage, page, count, listMapping, refreshBool } = props;
  const [searchedParams] = useSearchParams();
  const initSearchParams = {
    ...rawSearchParams,
    batchId: searchedParams.get("batchId") || "",
  };
  const [fields, setFields] =
    useState<OperationsTransactionSearchParamsFace>(initSearchParams);
  const DateObj = {
    Created: useDatePicker(defaultDates),
    Modified: useDatePicker(),
  };
  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  const setAssets = (assetName: string[]) =>
    setFields((fields) => ({ ...fields, assetName }));
  const getParams = () => {
    const { chain_name, batchId, ...rest } = fields;
    const { chain_id, chain_type } = selectChainByPieces({ chain_name });
    return {
      ...rest,
      created_date_from: DateObj.Created.start,
      created_date_to: DateObj.Created.end,
      last_modified_date_from: DateObj.Modified.start,
      last_modified_date_to: DateObj.Modified.end,
      batch_id: batchId ? [batchId] : [],
      chain_id,
      chain_type,
      page,
      pageSize: tableConfig.pageSize,
    };
  };

  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  useEffect(() => {
    const fetch = async () => {
      zusDialog.openExtra("loadingDialog");
      const res = await getOperationTransaction({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    };
    fetch();
  }, [page, cacheParams, refreshBool]);

  useEffect(() => {
    setAssets([]);
  }, [fields.chain_name]);

  const onSearch = async () => {
    setPage(0);
    setCacheParams(params);
  };
  const onReset = () => {
    setFields(initSearchParams);
    DateObj.Created.clearDate();
    DateObj.Modified.clearDate();
  };

  const onArraySelectChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const newValue = typeof value === "string" ? value.split(",") : value;
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getOperationTransaction({ ...params, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count);
    if (rawRes.length === 0) return;
    const res = listMapping("name", rawRes, translate);
    const config = {};
    downloadFiles(`Operation Transaction Records`, res, config);
  };
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const F = genField({ t }, [
    ["created_date", <DateObj.Created.Picker type="dateTime" />],
    ["last_modified_time", <DateObj.Modified.Picker type="dateTime" />],
    [
      "batch_id",
      <MpTextField
        fullWidth={true}
        name="batchId"
        label={t("phBatch_id")}
        value={fields.batchId}
        onChange={onChange("batchId")}
      />,
    ],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.assetName}
        allItems={allAssetNames}
      />,
    ],
    [
      "status",
      <FormControl>
        <InputLabel>{t("phStatus")}</InputLabel>
        <Select
          name="statuses"
          multiple
          value={fields.statuses}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(
            Object.entries(enumOperationTransactionStatus)
          ).map(([id, name]: any, i: string) => (
            <MenuItem key={i} value={id}>
              {translate(
                `enumConstants.${enumOperationTransactionStatus[String(id)]}`
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
  ]);

  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        <GeneralBtn
          label="export"
          isHidden={!hasPermission(Pkey.Export)}
          onClick={onExport}
        />
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.created_date}
        {F.last_modified_time}
        {F.chain_name}
        {F.asset_name}
        {F.status}
        {F.batch_id}
        {F.operation_type}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  translate: any
): any[][] => {
  const t = (key: string) => translate(`operations.${key}`);
  const res = array.map((item: any) => {
    // const parseJson = (input: any) =>
    // input ? JSON.stringify(JSON.parse(input), null, 3) : "";
    const mappedResult = [
      ["batch_id", t("batch_id"), item.batch_id],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(String(item?.batch?.chain_type), item?.batch?.chain_id)
          ?.name,
      ],
      ["step_seq", t("step_seq"), item.step_seq],
      ["tx_seq", t("tx_seq"), item.tx_seq],
      [
        "status",
        t("status"),
        translate(
          `enumConstants.${enumOperationTransactionStatus[String(item.status)]}`
        ),
      ],
      [
        "tx_status",
        t("tx_status"),
        Number(item.status) === 1 || Number(item.status) === 2
          ? ""
          : translate(`enumConstants.${String(item.tx_status)}`),
      ],
      [
        "operation_type",
        t("operation_type"),
        translate(
          `enumConstants.${findEnumKey(enumOperationType, item.operation_type)}`
        ),
      ],
      ["asset_name", t("asset_name"), item.asset_name],
      ["tx_hash", t("tx_hash"), item.tx_hash],

      ["error_reason", t("error_reason"), item.error_reason],
      ["remark", t("remark"), item.remarks],
      [
        "created_date",
        t("created_date"),
        toDisplayTime(item?.batch?.created_date),
      ],
      [
        "last_modified_date",
        t("last_modified_date"),
        toDisplayTime(item?.batch?.last_modified_date),
      ],
    ];
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
